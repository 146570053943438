import React from "react";
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import { Route, Routes } from 'react-router-dom';
import ServicesPage from './components/ServicesPage';
import Contact from './components/Contact';
import References from './components/References';

function App() {
    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/hizmetlerimiz" element={<ServicesPage />} />
                <Route path="/iletisim" element={<Contact />} />
                <Route path="/referanslarimiz" element={<References />} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;