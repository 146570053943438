import React from "react";

const ServicesPage = () => {
	return (
		<div className='services-page'>
			<h1>Hizmetlerimiz</h1>
			<div className='card-cont'>
				<div className='card'>
					<div className='cover item-a'>
						<h1>
							Genel Bina Yönetim Hizmetleri
							<br />
						</h1>
						<div className='card-back'>
							<p>
								PROTEAM YÖNETİM, tecrübeli kadrosu ile tüm
								Gelir-Gider hesaplarınızı, defterlerinizi tutar,
								Personel bordro ve Sgk işlemlerinizi yapar,
								Genel Kurul hazırlıklarınızı yapar. Ve Teknik
								arıza ve bakım, Temizlik, Güvenlik, Bahçe bakım
								ve Haşere ilaçlama hizmetlerinizi ve
								yönetilmesini sağlar.
							</p>
						</div>
					</div>
				</div>
				<div className='card'>
					<div className='cover item-b'>
						<h1>
							Bütçe
							<br />
							Yönetim Hizmetleri
						</h1>
						<div className='card-back'>
							<p>
								Aidat bütçeniz PROTEAM YÖNETİM’in hesap
								uzmanlarınca yapılarak, giderlerin bütçeye uygun
								şekilde gerçekleşip gerçekleşmediği de daima
								kontrol altında tutulur. Cari ödemelerinizin
								dengeli yapılması sağlanır. Gerekli durumlarda
								yatırım bütçeniz yapılarak onaya sunulur.
							</p>

						</div>
					</div>
				</div>
				<div className='card'>
					<div className='cover item-c'>
						<h1>
							Taşere
							<br />
							Hizmetler Yönetimi
						</h1>
						<div className='card-back'>
							<p>
								Site veya apartmanınız da ki yaşamın konforunu
								üst seviyede tutmak için kaçınılmaz önemi olan
								birimlerin (Muhasebe, Teknik hizmetler,
								Güvenlik, Temizlik, Bahçe peyzaj ve İlaçlama),
								görev tanımlarına uygun şekilde yönetilmesi,
								tesisin varlık değerinin de yükseltilmesini
								sağlamak.
							</p>

						</div>
					</div>
				</div>
				<div className='card'>
					<div className='cover item-d'>
						<h1>
							Hukuk
							<br />
							Hizmetleri
						</h1>
						<div className='card-back'>
							<p>
								PROTEAM YÖNETİM, geçmişte ki deneyimleri ile,
								çıkabilecek tüm hukuki problemlere müdahale
								ederek hem danışmanlık hemde gereken hukuki
								yöntem ve çözümleri üretir. 634 sayılı Kat
								Mülkiyeti Kanunu ve diğer ilgili tüm kanunlara
								uygun olarak çalışma yapar.
							</p>
						</div>
					</div>
				</div>
				<div className='card'>
					<div className='cover item-e'>
						<h1>
							Teknik
							<br />
							Hizmetler
						</h1>
						<div className='card-back'>
							<p>
								Oluşabilecek arızalara karşı bakımların ve bakım
								anlaşmalarının yapılması. Oluşan arızalara hemen
								müdahalelerin sağlanarak çözüm üretilmesi. Tüm
								Asansörlerin, Mekanik sistemlerin, Elektrik
								sistemlerin, Havuz sistemlerinin vb teknik
								ekipmanların idamesi.
							</p>

						</div>
					</div>
				</div>
				<div className='card'>
					<div className='cover item-f'>
						<h1>
							Temizlik
							<br />
							Hizmetleri
						</h1>
						<div className='card-back'>
							<p>Tüm ortak alanların temizlik işlerinin yapılması
								için personelin eğitimi ve idamesini sağlamak.
								Taşeron firma ile çalışılacaksa bunu tesbit
								etmek, çalışılabilecek firma araştırmalarını
								yapmak. Anlaşılan firmayı yönetmek ve kontrol
								etmek.
							</p>
						</div>
					</div>
				</div>
				<div className='card'>
					<div className='cover item-g'>
						<h1>
							Güvenlik Hizmetleri
							<br />
						</h1>
						<div className='card-back'>
							<p>
								Resmi lisanslı güvenlik firmaları ile birlikte
								çalışarak, yaşayanların huzurlu, güvenli bir
								ortamda yaşamlarını sürdürmeleri için gerekli
								Güvenlik gözetim ve denetim hizmetlerini
								sağlarız. Güvenlik hizmetlerinde ki eksiklikleri
								düzeltir ve daimi olara güvenliği üst düzeyde
								tutmaya önem veririz.
							</p>
						</div>
					</div>
				</div>
				<div className='card'>
					<div className='cover item-h'>
						<h1>
							Bahçe
							<br />
							ve Peyzaj Hizmetleri
						</h1>
						<div className='card-back'>
							<p>
								Aidat bütçeniz PROTEAM YÖNETİM’in hesap
								uzmanlarınca yapılarak, giderlerin bütçeye uygun
								şekilde gerçekleşip gerçekleşmediği de daima
								kontrol altında tutulur. Cari ödemelerinizin
								dengeli yapılması sağlanır. Gerekli durumlarda
								yatırım bütçeniz yapılarak onaya sunulur.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServicesPage;
