import React from 'react';
import segaCennet from '../images/segacennet3.jpg';
import segaIstanbul from '../images/segaist8.jpg';
import segaPlus from '../images/segaplus-2.jpg';
import begonyaPark from '../images/begonyapark.jpg';
import esinApartman from '../images/esinapartman.jpg';
import truvaGol from '../images/truvagolevleri.jpg';
import segaMarin from '../images/segaMarin.png';
import bathonea from '../images/bathonea.png';
import segaCadde from '../images/segaCadde.png';
const References = () => {
	return (
		<div className='references'>
			<h1>Referanslarımız</h1>
			<div className='ref-container'>
				<div className='grid-container'>
					<div className='grid-item'>
						<img
							src={segaCennet}
							alt='segacennet'
						/>
						<div>
							<h2>Sega Cennet</h2>
							<a
								href='https://segacennet.com/'
								target='_blank' rel='noopener noreferrer'
							>
								Daha Fazla Gör
							</a>
						</div>
					</div>
					<div className='grid-item'>
						<img
							src={segaIstanbul}
							alt='segaist'
						/>
						<div>
							<h2>Sega İstanbul</h2>
							<a
								href='https://segaistanbul.com/'
								target='_blank' rel='noopener noreferrer'
							>
								Daha Fazla Gör
							</a>
						</div>
					</div>
					<div className='grid-item'>
						<img
							src={segaPlus}
							alt='segaplus'
						/>
						<div>
							<h2>Sega Plus</h2>
							<a
								href='https://www.segayapi.com.tr/sega-plus'
								target='_blank' rel='noopener noreferrer'
							>
								Daha Fazla Gör
							</a>
						</div>
					</div>
					<div className='grid-item'>
						<img
							src={begonyaPark}
							alt='begonyapark'
						/>
						<div>
							<h2>Begonya Park Evleri</h2>
							<a
								href='https://www.askonyapi.com.tr/tr/proje/begonya-park-evleri'
								target='_blank' rel='noopener noreferrer'
							>
								Daha Fazla Gör
							</a>
						</div>
					</div>
					<div className='grid-item'>
						<img
							src={esinApartman}
							alt='esinapt'
						/>
						<div>
							<h2>Esin Apartman</h2>
							<a
								href='https://www.askonyapi.com.tr'
								target='_blank' rel='noopener noreferrer'
							>
								Daha Fazla Gör
							</a>
						</div>
					</div>
					<div className='grid-item'>
						<img
							src={truvaGol}
							alt='truvagol'
						/>
						<div>
							<h2>Truva Göl Evleri</h2>
							<a href='https://www.segainsaat.com/gol-truva' target='_blank' rel='noopener noreferrer'>
								Daha Fazla Gör
							</a>
						</div>
					</div>
					<div className='grid-item'>
						<img
							src={bathonea}
							alt='Bathonea'
						/>
						<div>
							<h2>Bathonea Residence</h2>
							<a href='https://www.instagram.com/akargrup/' target='_blank' rel='noopener noreferrer'>
								Daha Fazla Gör
							</a>
						</div>
					</div>
					<div className='grid-item'>
						<img
							src={segaCadde}
							alt='Sega Cadde'
						/>
						<div>
							<h2>Sega Cadde</h2>
							<a href='https://www.segayapi.com.tr/sega-cadde' target='_blank' rel='noopener noreferrer'>
								Daha Fazla Gör
							</a>
						</div>
					</div>
					<div className='grid-item'>
						<img
							src={segaMarin}
							alt='segamarin'
						/>
						<div>
							<h2>Sega Marin</h2>
							<a href='https://www.segainsaat.com/sega-marin' target='_blank' rel='noopener noreferrer'>
								Daha Fazla Gör
							</a>
						</div>
					</div>
				</div>


			</div>
		</div>
	);
};

export default References;
