import React from "react";

const Contact = () => {
	return (
		<div className='contact'>
			<h1>İletişim</h1>
			<iframe title={"firstMapTitle"}
					src='https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3011.815231514063!2d28.785163415413333!3d40.98552657930315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDDCsDU5JzA3LjkiTiAyOMKwNDcnMTQuNSJF!5e0!3m2!1sen!2str!4v1686608753720!5m2!1sen!2str'
					allowFullScreen=''
					loading='lazy'
					referrerPolicy='no-referrer-when-downgrade'
			></iframe>
			<p>
				Şenlikköy Mahallesi, Yaşar Kemal Sokak, No: 3, D: 5, Florya,
				34153, Bakırköy/İstanbul
			</p>
			<iframe title={"secondMapTitle"}
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d188.204527068997!2d28.766134622678777!3d40.99740994789272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa15ff6f185a3%3A0x50c2b30e54580493!2zRmF0aWgsIFByb2Zlc3NvciBEb2N0b3IgQXppeiBTYW5jYXIgQ2QgTm86NzIsIDM0MjkwIEvDvMOnw7xrw6dla21lY2UvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1722369654670!5m2!1str!2str"
				allowFullScreen=""
				loading="lazy"
				referrerPolicy="no-referrer-when-downgrade"></iframe>
			<p>
				2. Şubemiz
			</p>
			<p>
				Fatih Mahallesi Aziz Sancar Caddesi no:72-74 Küçükçekmece/Istanbul
			</p>
			<p>
				<strong><a href='tel:05078098989'>0(507)809 8989</a></strong>
				<strong><a href='tel:05058098989'>0(505)809 8989</a></strong>
				<strong><a href='tel:05418098989'>0(541)809 8989</a></strong>
			</p>
			<p>
				<a href='mailto:info@proteamyonetim.com'>info@proteamyonetim.com</a>
			</p>
		</div>
	);
};

export default Contact;
