import React from "react";
import { Link } from 'react-router-dom';
import {useRef} from "react";
import newLogo_300 from '../images/newlogo-300x131.png'
const Header = () => {
	const burgerRef = useRef();

	const handleResponsive = () => {
		if (burgerRef.current.className === 'responsive-header') {
			burgerRef.current.className += ' active';
		} else {
			burgerRef.current.className = 'responsive-header';
		}
	};

	return (
		<>
			<div className='header'>
				<div className='inner-header'>
					<Link to='/'>
						<img src={newLogo_300} alt='Logo'/>
					</Link>
					<ul className='links'>
						<li>
							<Link to='/'>Ana Sayfa</Link>
						</li>
						<li>
							<Link to='/hizmetlerimiz'>Hizmetlerimiz</Link>
						</li>
						<li>
							<Link to='/referanslarimiz'>Referanslarımız</Link>
						</li>
						<li>
							<Link to='/iletisim'>İletişim</Link>
						</li>
						<li
							className='burger'
							onClick={handleResponsive}
						>
							<i className='fa fa-bars'></i>
						</li>
					</ul>
				</div>
			</div>
			<div
				className='responsive-header'
				ref={burgerRef}
			>
				<div
					className='close'
					onClick={handleResponsive}
				>
					x
				</div>
				<ul className='links'>
					<li onClick={handleResponsive}>
						<Link to='/'>Ana Sayfa</Link>
					</li>
					<li onClick={handleResponsive}>
						<Link to='/hizmetlerimiz'>Hizmetlerimiz</Link>
					</li>
					<li onClick={handleResponsive}>
						<Link to='/referanslarimiz'>Referanslarımız</Link>
					</li>
					<li onClick={handleResponsive}>
						<Link to='/iletisim'>İletişim</Link>
					</li>
				</ul>
			</div>
		</>
	);
};

export default Header;
