import React, {useEffect, useState} from "react";
import '../modal.css';
import logo from '../images/newlogo-300x131.png'
import segaCennet from '../images/segacennet3.jpg';
import segaIstanbul from '../images/segaist8.jpg';
import segaPlus from '../images/segaplus-2.jpg';
import begonyaPark from '../images/begonyapark.jpg';
import esinApartman from '../images/esinapartman.jpg';
import truvaGol from '../images/truvagolevleri.jpg';
import segaMarin from '../images/segaMarin.png';
import bathonea from '../images/bathonea.png';
import segaCadde from '../images/segaCadde.png';

const Slider = () => {
	const images = [segaCadde,segaCennet,segaIstanbul,segaPlus,begonyaPark,esinApartman,truvaGol,segaMarin,bathonea];
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [currentIndex, setCurrentIndex] = useState(0);
	const [prevIndex, setPrevIndex] = useState(images.length - 1);

	useEffect(() => {
		const interval = setInterval(() => {
			setPrevIndex(currentIndex);
			setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
		}, 6000);

		return () => clearInterval(interval);
	}, [currentIndex, images.length]);
	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setSearchTerm('');
	};

	const handleItemClick = (url) => {
		window.location.href = url; // Open the URL in a new tab
	};
	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value.toLowerCase());
	};

	const items = [
		{ name: 'BATHONEA RESIDANCE', url: 'https://aidattakipsistemi.com/?prositeid=42892' },
		{ name: 'BEGONYA PARK EVLERİ', url: 'https://aidattakipsistemi.com/?prositeid=39325' },
		{ name: 'ESEN SİTESİ', url: 'https://aidattakipsistemi.com/?prositeid=39312' },
		{ name: 'GOLDEN SAFİR EVLERİ', url: 'https://aidattakipsistemi.com/?prositeid=40491' },
		{ name: 'GÖL MARİN EVLERİ', url: 'https://aidattakipsistemi.com/?prositeid=39326' },
		{ name: 'NOVARS LALE GOLD LIFE', url: 'https://aidattakipsistemi.com/?prositeid=39313' },
		{ name: 'SEGA CENNET EVLERI', url: 'https://aidattakipsistemi.com/?prositeid=40489' },
		{ name: 'SEGA ISTANBUL', url: 'https://aidattakipsistemi.com/?prositeid=39320' },
		{ name: 'SEGA MODERN APARTMANI', url: 'https://aidattakipsistemi.com/?prositeid=39314' },
		{ name: 'SEGA PLUS EVLERI', url: 'https://aidattakipsistemi.com/?prositeid=40490' },
		{ name: 'TRUVA GOL EVLERI', url: 'https://aidattakipsistemi.com/?prositeid=41382' },

	];
	const filteredItems = items.filter((item) =>
		item.name.toLowerCase().includes(searchTerm)
	);
	return (
		<div className={'slider'}>
			{images.map((image, index) => (
				<img
					key={index}
					src={image}
					alt={`slider-img-${index}`}
					className={`slider-img ${
						index === currentIndex ? "slide-in" : index === prevIndex ? "slide-out" : ""
					}`}
				/>
			))}
			<div className="slider-text">
				<h1>PROTEAM YÖNETİM</h1>
				<h4>HOŞ GELDİNİZ</h4>
				<a className="info-link" href="tel:05078098989">
					Bilgi Al
				</a>
				<button className="modal-button" onClick={handleOpenModal}>
					Site Sakin Girişi
				</button>
			</div>

			{isModalOpen && (
				<div className={'modal'}>
					<div className={'modal-content'}>
						<div className={'modal-header'}>
							<h2>PROTEAM YÖNETİM</h2>
							<span className={'close'} onClick={handleCloseModal}>&times;</span>
						</div>
						<input
							type="text"
							placeholder="Site / Apartman Ara"
							className={'search-input'}
							value={searchTerm}
							onChange={handleSearchChange}
						/>
						<ul className={'item-list'}>
							{filteredItems.map((item, index) => (
								<li key={index} onClick={() => handleItemClick(item.url)}>
									<input type="radio" name="site" id={`site-${index}`}/>
									<label htmlFor={`site-${index}`}>{item.name}</label>
								</li>
							))}
						</ul>
						<div className={'footerCustom'}>
							<img src={logo} alt="Proteam Logo" className={'logo'}/>
							<span>Proteam Yonetim</span>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Slider;
