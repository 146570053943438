import React from "react";

const About = () => {
	return (
		<div className='about'>
			<h1>Hakkımızda</h1>
			<div className='about-text'>
				<b>Proteam Yönetim</b> olarak; İnsanın yaşam alanında mutlu
				olmasını temel görev kabul ederek, mülkiyet ve insan
				ilişkilerindeki gerek doğal gerekse suni nedenlerden kaynaklanan
				sorunlara çözüm getirerek hizmetleri üretmek için çalışıyoruz.
				Bununla beraber site yönetim, blok ve apartman yönetim
				hizmetlerinin Türkiye’deki kalitesini yükseltmek üzere, uzman
				kadromuzla ve çözüm ortaklarımız ile standartları yüksek
				projeler ve hizmetler sunmak için çalışıyoruz.
			</div>
		</div>
	);
};

export default About;
