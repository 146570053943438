import React from "react";
import Slider from './Slider';
import About from './About';
import Services from './Services';

const Home = () => {
	return (
		<div className='home'>
			<Slider />
			<About />
			<Services />
		</div>
	);
};

export default Home;
